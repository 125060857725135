@charset "UTF-8";
@import '~tiny-slider/dist/tiny-slider.css';
@import '~baguettebox.js/dist/baguetteBox.min.css';
@import '~tiny-slider/dist/tiny-slider.css'; /* Document
 * ========================================================================== */
/**
 * 1. Add border box sizing in all browsers (opinionated).
 * 2. Backgrounds do not repeat by default (opinionated).
 */
*,
::before,
::after {
  box-sizing: border-box; /* 1 */
  background-repeat: no-repeat; /* 2 */
}

/**
 * 1. Add text decoration inheritance in all browsers (opinionated).
 * 2. Add vertical alignment inheritance in all browsers (opinionated).
 */
::before,
::after {
  text-decoration: inherit; /* 1 */
  vertical-align: inherit; /* 2 */
}

/**
 * 1. Use the default cursor in all browsers (opinionated).
 * 2. Change the line height in all browsers (opinionated).
 * 3. Breaks words to prevent overflow in all browsers (opinionated).
 * 4. Use a 4-space tab width in all browsers (opinionated).
 * 5. Remove the grey highlight on links in iOS (opinionated).
 * 6. Prevent adjustments of font size after orientation changes in iOS.
 */
:where(:root) {
  cursor: default; /* 1 */
  line-height: 1.5; /* 2 */
  overflow-wrap: break-word; /* 3 */
  -moz-tab-size: 4; /* 4 */
  tab-size: 4; /* 4 */
  -webkit-tap-highlight-color: transparent; /* 5 */
  -webkit-text-size-adjust: 100%; /* 6 */
}

/* Sections
 * ========================================================================== */
/**
 * Remove the margin in all browsers (opinionated).
 */
:where(body) {
  margin: 0;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Edge, Firefox, and Safari.
 */
:where(h1) {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
 * ========================================================================== */
/**
 * Remove the margin on nested lists in Chrome, Edge, and Safari.
 */
:where(dl, ol, ul) :where(dl, ol, ul) {
  margin: 0;
}

/**
 * 1. Correct the inheritance of border color in Firefox.
 * 2. Add the correct box sizing in Firefox.
 */
:where(hr) {
  color: inherit; /* 1 */
  height: 0; /* 2 */
}

/**
 * Remove the list style on navigation lists in all browsers (opinionated).
 */
:where(nav) :where(ol, ul) {
  list-style-type: none;
  padding: 0;
}

/**
 * Prevent VoiceOver from ignoring list semantics in Safari (opinionated).
 */
:where(nav li)::before {
  content: "​";
  float: left;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 * 3. Prevent overflow of the container in all browsers (opinionated).
 */
:where(pre) {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
  overflow: auto; /* 3 */
}

/* Text-level semantics
 * ========================================================================== */
/**
 * Add the correct text decoration in Safari.
 */
:where(abbr[title]) {
  text-decoration: underline;
  text-decoration: underline dotted;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
:where(b, strong) {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
:where(code, kbd, samp) {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */
:where(small) {
  font-size: 80%;
}

/* Embedded content
 * ========================================================================== */
/*
 * Change the alignment on media elements in all browsers (opinionated).
 */
:where(audio, canvas, iframe, img, svg, video) {
  vertical-align: middle;
}

/**
 * Remove the border on iframes in all browsers (opinionated).
 */
:where(iframe) {
  border-style: none;
}

/**
 * Change the fill color to match the text color in all browsers (opinionated).
 */
:where(svg:not([fill])) {
  fill: currentColor;
}

/* Tabular data
 * ========================================================================== */
/**
 * 1. Collapse border spacing in all browsers (opinionated).
 * 2. Correct table border color inheritance in all Chrome, Edge, and Safari.
 * 3. Remove text indentation from table contents in Chrome, Edge, and Safari.
 */
:where(table) {
  border-collapse: collapse; /* 1 */
  border-color: inherit; /* 2 */
  text-indent: 0; /* 3 */
}

/* Forms
 * ========================================================================== */
/**
 * Remove the margin on controls in Safari.
 */
:where(button, input, select) {
  margin: 0;
}

/**
 * Correct the inability to style buttons in iOS and Safari.
 */
:where(button, [type=button i], [type=reset i], [type=submit i]) {
  -webkit-appearance: button;
}

/**
 * Change the inconsistent appearance in all browsers (opinionated).
 */
:where(fieldset) {
  border: 1px solid #a0a0a0;
}

/**
 * Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */
:where(progress) {
  vertical-align: baseline;
}

/**
 * 1. Remove the margin in Firefox and Safari.
 * 3. Change the resize direction in all browsers (opinionated).
 */
:where(textarea) {
  margin: 0; /* 1 */
  resize: vertical; /* 3 */
}

/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */
:where([type=search i]) {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}

/**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */
::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/* Interactive
 * ========================================================================== */
/*
 * Add the correct styles in Safari.
 */
:where(dialog) {
  background-color: white;
  border: solid;
  color: black;
  height: -moz-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: fit-content;
}

:where(dialog:not([open])) {
  display: none;
}

/*
 * Add the correct display in Safari.
 */
:where(details > summary:first-of-type) {
  display: list-item;
}

/* Accessibility
 * ========================================================================== */
/**
 * Change the cursor on busy elements in all browsers (opinionated).
 */
:where([aria-busy=true i]) {
  cursor: progress;
}

/*
 * Change the cursor on control elements in all browsers (opinionated).
 */
:where([aria-controls]) {
  cursor: pointer;
}

/*
 * Change the cursor on disabled, not-editable, or otherwise
 * inoperable elements in all browsers (opinionated).
 */
:where([aria-disabled=true i], [disabled]) {
  cursor: not-allowed;
}

/*
 * Change the display on visually hidden accessible elements
 * in all browsers (opinionated).
 */
:where([aria-hidden=false i][hidden]) {
  display: initial;
}

:where([aria-hidden=false i][hidden]:not(:focus)) {
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

/*
 * 1. Remove animations when motion is reduced (opinionated).
 * 2. Remove fixed background attachments when motion is reduced (opinionated).
 * 3. Remove timed scrolling behaviors when motion is reduced (opinionated).
 * 4. Remove transitions when motion is reduced (opinionated).
 */
@media (prefers-reduced-motion: reduce) {
  *,
  ::before,
  ::after {
    animation-delay: -1ms !important; /* 1 */
    animation-duration: 1ms !important; /* 1 */
    animation-iteration-count: 1 !important; /* 1 */
    background-attachment: initial !important; /* 2 */
    scroll-behavior: auto !important; /* 3 */
    transition-delay: 0s !important; /* 4 */
    transition-duration: 0s !important; /* 4 */
  }
}
/**
 * 1. Change the inconsistent appearance in all browsers (opinionated).
 * 2. Add typography inheritance in all browsers (opinionated).
 */
:where(button, input, select, textarea) {
  background-color: transparent; /* 1 */
  border: 1px solid WindowFrame; /* 1 */
  color: inherit; /* 1 */
  font: inherit; /* 2 */
  letter-spacing: inherit; /* 2 */
  padding: 0.25em 0.375em; /* 1 */
}

/**
 * Change the inconsistent appearance in all browsers (opinionated).
 */
:where(select) {
  appearance: none;
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='4'%3E%3Cpath d='M4 0h6L7 4'/%3E%3C/svg%3E") no-repeat right center/1em;
  border-radius: 0;
  padding-right: 1em;
}

/**
 * Don't show the arrow for multiple choice selects
 */
:where(select[multiple]) {
  background-image: none;
}

/**
 * Remove the border and padding in all browsers (opinionated).
 */
:where([type=color i], [type=range i]) {
  border-width: 0;
  padding: 0;
}

*,
::before,
::after {
  mask-repeat: no-repeat;
}

:where(*) {
  margin: 0;
}

/**
 * 1. Don't behave as inline element by default which creates weird situations with margins
 */
:where(img) {
  display: inline-block; /* 1 */
  max-width: 100%;
  vertical-align: top;
}

/**
 * https://www.zachleat.com/web/fluid-images/
 */
:where(img[width][height]) {
  height: auto;
}

:where(dl, ol, ul) {
  padding: 0;
  list-style: none;
}

/**
 * Restrict sizing to the page width in all browsers (opinionated).
 */
:where(iframe, img, input, video, select, textarea) {
  max-width: 100%;
}

/**
 * Inherit parent color, more convenient than default browser blue
 */
:where(a) {
  color: inherit;
}

:where(figure) {
  margin-inline: 0;
}

/**
 * HARD reset to remove shadow in input for IOS
 */
:where(input:not([type=checkbox], [type=radio])) {
  -webkit-appearance: none !important;
}

/**
 * https://github.com/mike-engel/a11y-css-reset
 */
*:focus:not(:focus-visible),
*::before:focus:not(:focus-visible),
*::after:focus:not(:focus-visible) {
  outline: none;
}

/* stylelint-disable declaration-colon-space-after, max-empty-lines */
:root {
  --transition-default: .2s ease-in-out;
  --transition-faster: .15s ease-in-out;
  --transition-fastest: .1s ease-in-out;
}

/* stylelint-disable declaration-colon-space-after, max-empty-lines */
:root {
  --color-white: hsl(0, 0%, 100%);
  --color-gray-light: hsl(0, 0%, 86% / .45);
  --color-gray: hsl(40, 12%, 80%);
  --color-gray-dark: hsl(30, 3%, 44%);
  --color-black: hsl(0, 0%, 0%);
  --color-red: red;
  --color-primary: hsl(222, 56%, 30%);
  --color-primary-light: hsl(200, 68%, 52%);
  --color-primary-contrast-text: var(--color-white);
  --color-secondary: hsl(200, 68%, 52%);
  --color-secondary-light: hsl(200, 68%, 62%);
  --color-secondary-contrast-text: var(--color-black);
  --color-text: hsl(0, 0%, 2%);
  --color-accent: var(--color-primary);
  --color-lines: var(--color-gray);
}

/* stylelint-disable declaration-colon-space-after, max-empty-lines */
:root {
  --font-primary: verdana, sans-serif;
  --font-secondary: verdana, sans-serif;
  --font-primary-weight-regular: 400;
  --font-primary-weight-medium: 500;
  --font-primary-weight-bold: 700;
  --font-secondary-weight-medium: 500;
  --font-secondary-weight-bold: 800;
}

/* stylelint-disable declaration-colon-space-after, max-empty-lines */
@custom-media --show-mobile-menu (max-width: 1119px);
@custom-media --show-desktop-menu (min-width: 1120px);
@custom-media --inner-container-start-vw-padding (max-width: 720px);
:root {
  --outer-container-max-width: auto;
  --inner-container-padding-inline: min(4vw, 25px);
  --inner-container-medium-max-width: 120rem;
  --article-container-max-width: 43em;
  --form-container-max-width: calc(68rem + 2 * var(--inner-container-padding-inline));
  --inner-container-max-width: 160rem;
  --layout-default-block-margin: 4em;
}

@custom-media --screen-huge             (width > 1900px);
@custom-media --screen-extra-large      (width < 1500px);
@custom-media --screen-large            (width < 1300px);
@custom-media --screen-medium-large     (width < 1160px);
@custom-media --screen-medium           (width < 991px);
@custom-media --screen-medium-small     (width < 765px);
@custom-media --screen-small            (width < 640px);
@custom-media --screen-mini-small       (width < 545px);
@custom-media --screen-mini             (width < 420px);
@custom-selector :--focus :hover, :focus, :active; /* stylelint-disable declaration-colon-space-after, max-empty-lines */
:root {
  --z-index-mobile-header: 99;
  --z-index-menu-button: 100;
}

@keyframes bounce-even {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    transform: translate3d(0, -1000px, 0) rotate(-6deg);
    opacity: 0;
  }
  60% {
    transform: translate3d(0, 25px, 0) rotate(-4deg);
    opacity: 1;
  }
  75% {
    transform: translate3d(0, -10px, 0) rotate(-2deg);
  }
  90% {
    transform: translate3d(0, 5px, 0) rotate(-2deg);
  }
  to {
    transform: translate3d(0, 0, 0) rotate(-3deg);
  }
}
@keyframes bounce-odd {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    transform: translate3d(0, -1000px, 0) rotate(6deg);
    opacity: 0;
  }
  60% {
    transform: translate3d(0, 25px, 0) rotate(4deg);
    opacity: 1;
  }
  75% {
    transform: translate3d(0, -10px, 0) rotate(2deg);
  }
  90% {
    transform: translate3d(0, 5px, 0) rotate(2deg);
  }
  to {
    transform: translate3d(0, 0, 0) rotate(3deg);
  }
}
:where(a:not([class])) {
  color: var(--anchor-color, var(--color-primary));
  text-decoration-line: underline;
  text-decoration-thickness: 2px;
  transition: 0.2s color ease-in-out;
}
:--focus:where(a:not([class])) {
  color: var(--color-primary-light);
}

:where(a[href^=tel]:not([class])) {
  cursor: inherit;
  color: inherit;
  text-decoration: inherit;
}
:where(a[href^=tel]:not([class])):--focus {
  cursor: inherit;
  color: inherit;
  text-decoration: inherit;
}

blockquote:not([class]) {
  font-size: 110%;
  font-style: italic;
}
blockquote:not([class])::before, blockquote:not([class])::after {
  content: '"';
  font-weight: var(--font-primary-weight-bold);
  color: var(--blockquote-quote-color, var(--color-secondary));
}

/**
 * You can use font-smoothing best when using light text on a dark background
 * This only works on Mac OS X
 *
 * More info:
 * - http://www.intridea.com/blog/2014/5/8/better-font-smoothing-in-chrome-on-mac-os-x
 * - http://maximilianhoffmann.com/posts/better-font-rendering-on-osx
 */
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* stylelint-disable media-feature-name-no-vendor-prefix, media-feature-name-no-unknown, csstools/media-use-custom-media */
  /* stylelint-enable */
  font-size: calc(1.6rem + (18 - 16) * (100vw - 32rem) / (1280 - 320));
  display: flex;
  flex-direction: column;
  min-width: 320px;
  height: 100%;
  line-height: 1.5;
  background-color: inherit;
}
@media only screen and (-webkit-min-device-pixel-ratio: 1.25), only screen and (min-device-pixel-ratio: 1.25), only screen and (min-resolution: 200dpi), only screen and (min-resolution: 1.25dppx) {
  body {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}
@media (min-width: 80rem) {
  body {
    font-size: 1.8rem;
  }
}
@media (max-width: 20rem) {
  body {
    font-size: 1.6rem;
  }
}
@media (--show-mobile-menu) {
  .-is-mobile-menu-open body {
    height: 100%;
  }
}
body.-overflow {
  overflow: hidden;
}
body > main {
  flex: 1 0 auto;
}
body > footer {
  flex-shrink: 0;
}

:where(button:not([class])), .button:where(:not(.prev-btn):not(.next-btn):not(.view-switch)), .button--slider:where(:not(.prev-btn):not(.next-btn):not(.view-switch)) {
  cursor: pointer;
  display: inline-block;
  margin: 0.7em 1em 0.7em 0;
  padding: 0.85em 1.4em 0.9em;
  font-family: var(--button-font-family, var(--font-primary));
  font-size: 1.7rem;
  font-weight: var(--font-primary-weight-bold);
  line-height: 1.25;
  color: var(--color-primary-contrast-text);
  text-decoration: none;
  background-color: var(--color-primary);
  border: 0;
  border-radius: var(--button-border-radius, 4px);
  transition: background-color 0.2s ease-out;
}
:where(button:not([class]))::after, .button:where(:not(.prev-btn):not(.next-btn):not(.view-switch))::after, .button--slider:where(:not(.prev-btn):not(.next-btn):not(.view-switch))::after {
  content: "";
  display: inline-block;
  width: 1rem;
  height: 1rem;
  margin-inline-start: 0.8em;
  background-color: currentcolor;
  transition: transform 0.2s ease-in-out;
  mask-image: url("../images/angle-right.svg");
  mask-position: right center;
  mask-size: contain;
}
:--focus:where(button:not([class])), .button:--focus:where(:not(.prev-btn):not(.next-btn):not(.view-switch)), .button--slider:--focus:where(:not(.prev-btn):not(.next-btn):not(.view-switch)) {
  background-color: var(--color-primary-light);
}
:--focus:where(button:not([class]))::after, .button:--focus:where(:not(.prev-btn):not(.next-btn):not(.view-switch))::after, .button--slider:--focus:where(:not(.prev-btn):not(.next-btn):not(.view-switch))::after {
  transform: translateX(0.4rem);
}

.button.-large, .-large.button--slider {
  font-size: calc(1.8rem + (23 - 18) * (100vw - 32rem) / (1280 - 320));
}
@media (min-width: 80rem) {
  .button.-large, .-large.button--slider {
    font-size: 2.3rem;
  }
}
@media (max-width: 20rem) {
  .button.-large, .-large.button--slider {
    font-size: 1.8rem;
  }
}
.button.-no-arrow::after, .-no-arrow.button--slider::after {
  display: none;
}

.button--slider {
  font-size: calc(1.8rem + (23 - 18) * (100vw - 32rem) / (1280 - 320));
}
@media (min-width: 80rem) {
  .button--slider {
    font-size: 2.3rem;
  }
}
@media (max-width: 20rem) {
  .button--slider {
    font-size: 1.8rem;
  }
}

fieldset:not([class]), .fieldset {
  margin-block: 2em;
  padding: 0;
  border: 0;
}

figure {
  position: relative;
  width: 100%;
}
.content figure {
  margin: 2rem 0;
}

:where(form:not([class])) button, .form button {
  margin-block-end: 0;
}

html {
  scroll-behavior: smooth;
  font-family: var(--font-primary);
  font-size: 62.5%;
  color: var(--color-text);
  accent-color: var(--color-accent);
  background-color: var(--html-background-color, transparent);
}
@media screen and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}
@media (--show-mobile-menu) {
  html.-is-mobile-menu-open {
    overflow: hidden;
    height: 100%;
  }
}

textarea:not([class]),
.textarea, select:not([class]), .select, input:not([class]), .input {
  font-size: calc(1.5rem + (17 - 15) * (100vw - 32rem) / (1280 - 320));
  width: 100%;
  margin: 0;
  padding: 1rem;
  font-family: var(--font-primary);
  line-height: normal;
}
@media (min-width: 80rem) {
  textarea:not([class]),
  .textarea, select:not([class]), .select, input:not([class]), .input {
    font-size: 1.7rem;
  }
}
@media (max-width: 20rem) {
  textarea:not([class]),
  .textarea, select:not([class]), .select, input:not([class]), .input {
    font-size: 1.5rem;
  }
}

textarea:not([class]),
.textarea, select:not([class]), .select, input:not([class]), .input {
  display: inline-block;
  box-sizing: border-box;
  padding: 0.7em;
  color: var(--color-text);
  word-break: normal;
  background-color: var(--color-white);
  border: 1px solid var(--input-border-color, var(--color-lines));
  border-radius: var(--form-input-border-radius, 0);
  transition: background-color var(--transition-default), border-color var(--transition-default);
}
textarea:not([class])::placeholder,
.textarea::placeholder, select:not([class])::placeholder, .select::placeholder, input:not([class])::placeholder, .input::placeholder {
  opacity: 0.8;
}
textarea:focus-visible:not([class]),
.textarea:focus-visible, select:focus-visible:not([class]), .select:focus-visible, input:focus-visible:not([class]), .input:focus-visible {
  border-color: transparent;
  outline: 2px solid var(--input-focus-outline-color, var(--color-lines));
}
textarea:disabled:not([class]),
.textarea:disabled, select:disabled:not([class]), .select:disabled, input:disabled:not([class]), .input:disabled {
  opacity: 0.6;
}
textarea:disabled:not([class]) + label,
.textarea:disabled + label, select:disabled:not([class]) + label, .select:disabled + label, input:disabled:not([class]) + label, .input:disabled + label {
  opacity: 0.8;
}

input:not([class])[type=checkbox], input:not([class])[type=radio], .input[type=checkbox], .input[type=radio] {
  display: inline-block;
  width: auto;
  height: 1.5em;
  margin-inline-start: 0.3rem;
  opacity: 0.65;
}
input:not([class])[type=checkbox]:focus, input:not([class])[type=radio]:focus, .input[type=checkbox]:focus, .input[type=radio]:focus {
  outline: auto;
}
input:not([class]).-auto-width, .input.-auto-width {
  width: auto;
}

input.special-password {
  display: none;
}

.label--option, label:not([class]), .label {
  cursor: pointer;
  display: inline-block;
  margin: 0 0 0.5em;
  font-size: inherit;
  line-height: 1.25;
  vertical-align: top;
}

label:not([class])[for*=IgnoreThis], .label[for*=IgnoreThis] {
  display: none;
}
label:not([class]) > small, .label > small {
  margin-inline-start: 0.5em;
  opacity: 0.9;
}

.label--option {
  margin-inline-start: 0.6em;
  font-weight: var(--font-primary-weight-regular);
  line-height: 1.5;
}

.label__info {
  font-weight: normal;
}

ul:not([class]) > li, ol:not([class]) > li, .list > li, .checklist > li {
  margin: 0 0 0.75em;
  padding: 0;
}
ul:not([class]) > li:last-child, ol:not([class]) > li:last-child, .list > li:last-child, .checklist > li:last-child {
  margin-block-end: 0;
}
ul:not([class]) > li > ul, ol:not([class]) > li > ul, .list > li > ul, .checklist > li > ul, ul:not([class]) > li > ol, ol:not([class]) > li > ol, .list > li > ol, .checklist > li > ol {
  margin-block: 0.75em 0;
}

ul:not([class]), ol:not([class]), .list, .checklist {
  margin-block-end: 1.7em;
  padding-inline-start: 1.5em;
}
ul:not([class]) li::marker, ol:not([class]) li::marker, .list li::marker, .checklist li::marker {
  color: var(--color-accent);
}

ul:not([class]), ul.list, ul.checklist {
  list-style-type: disc;
}

ol:not([class]), ol.list, ol.checklist {
  list-style: decimal;
}
ol:not([class]) li::marker, ol.list li::marker, ol.checklist li::marker {
  font-size: 120%;
}

p {
  margin-block: 0 1.5em;
}
p:empty {
  display: none;
}

select:not([class]), .select {
  padding-inline-end: 2.8rem;
  background-image: url("../images/angle-down.svg");
  background-repeat: no-repeat;
  background-position: right 1em center;
  background-size: 1.2rem 0.8rem;
}
select:not([class]):not(:disabled), .select:not(:disabled) {
  cursor: pointer;
}

::selection {
  color: var(--selection-colors-color, currentcolor);
  text-shadow: none;
  background-color: var(--selection-colors-backgroundcolor, var(--color-secondary-light));
}

strong, b {
  font-weight: var(--font-primary-weight-bold);
}

:root {
  /* stylelint-disable declaration-colon-space-after  */
  --table-border-color: var(--text-color-primary);
  --table-head-color: var(--text-color-primary);
  --table-head-background-color: transparent;
  --table-row-color: var(--text-color-primary);
  --table-row-background-color-even: var(--color-blue-white);
  --table-row-background-color-odd: transparent;
  /* stylelint-enable */
}

table:not([class]), .table {
  table-layout: fixed;
  border-spacing: 0;
  border-collapse: collapse;
  min-width: 30vw;
  max-width: 100%;
  margin-block-end: 3em;
  border: 0;
}
table:not([class]) tr:nth-child(even), .table tr:nth-child(even) {
  background-color: var(--table-row-background-color-even);
}
table:not([class]) tr:nth-child(odd), .table tr:nth-child(odd) {
  background-color: var(--table-row-background-color-odd);
}
table:not([class]) th, table:not([class]) td, .table th, .table td {
  padding: 1.2em 1em;
  color: var(--table-row-color);
  text-align: start;
  vertical-align: top;
  border: 1px solid var(--table-border-color);
}
@media (--screen-mini) {
  table:not([class]) th, table:not([class]) td, .table th, .table td {
    padding: 1em 0.6em;
    font-size: 1.4rem;
  }
}
table:not([class]) th, .table th {
  color: var(--table-head-color);
  text-align: start;
  background: var(--table-head-background-color);
}
@media (--screen-medium) {
  table:not([class]), .table {
    min-width: 50vw;
  }
}
@media (--screen-medium-small) {
  table:not([class]), .table {
    width: 100%;
    min-width: 0;
  }
}

textarea:not([class]),
.textarea {
  margin-block-end: 0.4em;
  line-height: 1.5;
}

.subtitle--1, .title--6, h6:not([class]), .title--5, h5:not([class]), .title--4, .title--footer, h4:not([class]), .title--3, h3:not([class]), .title--2, h2:not([class]), .title--1, h1:not([class]) {
  position: relative;
  display: block;
  margin-block: var(--title-margin-block, 1.25em 0.5em);
  font-family: var(--font-secondary);
  font-weight: var(--font-secondary-weight-bold);
  line-height: var(--title-line-height, 1.25);
  color: var(--title-color, var(--color-primary));
  text-shadow: var(--title-text-shadow, none);
}
.subtitle--1 a, .title--6 a, h6:not([class]) a, .title--5 a, h5:not([class]) a, .title--4 a, .title--footer a, h4:not([class]) a, .title--3 a, h3:not([class]) a, .title--2 a, h2:not([class]) a, .title--1 a, h1:not([class]) a {
  color: inherit;
  text-decoration: none;
}
.subtitle--1 a:--focus, .title--6 a:--focus, h6:not([class]) a:--focus, .title--5 a:--focus, h5:not([class]) a:--focus, .title--4 a:--focus, .title--footer a:--focus, h4:not([class]) a:--focus, .title--3 a:--focus, h3:not([class]) a:--focus, .title--2 a:--focus, h2:not([class]) a:--focus, .title--1 a:--focus, h1:not([class]) a:--focus {
  color: var(--title-hover-color, var(--color-secondary));
}
.-centered.subtitle--1, .-centered.title--6, h6.-centered:not([class]), .-centered.title--5, h5.-centered:not([class]), .-centered.title--4, .-centered.title--footer, h4.-centered:not([class]), .-centered.title--3, h3.-centered:not([class]), .-centered.title--2, h2.-centered:not([class]), .-centered.title--1, h1.-centered:not([class]) {
  text-align: center;
}

.title--1, h1:not([class]) {
  font-size: calc(3.8rem + (60 - 38) * (100vw - 32rem) / (1280 - 320));
  margin-block-start: 0;
  font-family: var(--title-1-font-family, var(--font-secondary));
  line-height: 1.15;
  text-transform: var(--title-1-text-transform, none);
}
@media (min-width: 80rem) {
  .title--1, h1:not([class]) {
    font-size: 6rem;
  }
}
@media (max-width: 20rem) {
  .title--1, h1:not([class]) {
    font-size: 3.8rem;
  }
}

.title--2, h2:not([class]) {
  font-size: calc(3rem + (43 - 30) * (100vw - 32rem) / (1280 - 320));
  /* .subtitle--1 + {
      margin-top: 0;
  } */
}
@media (min-width: 80rem) {
  .title--2, h2:not([class]) {
    font-size: 4.3rem;
  }
}
@media (max-width: 20rem) {
  .title--2, h2:not([class]) {
    font-size: 3rem;
  }
}
.title--2.-extra-margin, h2:not([class]).-extra-margin {
  margin-block-end: 1.5em;
}

.title--3, h3:not([class]) {
  font-size: calc(2.2rem + (28 - 22) * (100vw - 32rem) / (1280 - 320));
}
@media (min-width: 80rem) {
  .title--3, h3:not([class]) {
    font-size: 2.8rem;
  }
}
@media (max-width: 20rem) {
  .title--3, h3:not([class]) {
    font-size: 2.2rem;
  }
}

.title--4, .title--footer, h4:not([class]) {
  font-size: calc(2rem + (24 - 20) * (100vw - 32rem) / (1280 - 320));
}
@media (min-width: 80rem) {
  .title--4, .title--footer, h4:not([class]) {
    font-size: 2.4rem;
  }
}
@media (max-width: 20rem) {
  .title--4, .title--footer, h4:not([class]) {
    font-size: 2rem;
  }
}

.title--5, h5:not([class]) {
  font-size: calc(1.9rem + (21 - 19) * (100vw - 32rem) / (1280 - 320));
}
@media (min-width: 80rem) {
  .title--5, h5:not([class]) {
    font-size: 2.1rem;
  }
}
@media (max-width: 20rem) {
  .title--5, h5:not([class]) {
    font-size: 1.9rem;
  }
}

.title--6, h6:not([class]) {
  font-size: calc(1.8rem + (20 - 18) * (100vw - 32rem) / (1280 - 320));
}
@media (min-width: 80rem) {
  .title--6, h6:not([class]) {
    font-size: 2rem;
  }
}
@media (max-width: 20rem) {
  .title--6, h6:not([class]) {
    font-size: 1.8rem;
  }
}

.subtitle--1 {
  font-size: calc(2.8rem + (36 - 28) * (100vw - 32rem) / (1280 - 320));
  inset-block-start: -18em;
  font-weight: normal;
  color: var(--color-blue-light);
  text-align: center;
}
@media (min-width: 80rem) {
  .subtitle--1 {
    font-size: 3.6rem;
  }
}
@media (max-width: 20rem) {
  .subtitle--1 {
    font-size: 2.8rem;
  }
}

.title--footer {
  margin-block: 1rem 1.5rem;
  color: inherit;
  text-shadow: none;
}

.title-card {
  margin-block: 0;
  font-size: 3.2rem;
  line-height: 1.1;
  color: var(--color-white);
}
.title-card.-small {
  margin-block-start: 0;
  font-size: 2.7rem;
}
@media (--screen-mini) {
  .title-card.-small {
    font-size: 2rem;
  }
}
@media (--screen-large) {
  .title-card {
    font-size: 2.8rem;
  }
}
@media (--screen-mini) {
  .title-card {
    font-size: 2rem;
  }
}

.l-header__inner, .l-container--inner, .l-container--outer, .l-article {
  position: relative;
  width: 100%;
  margin-inline: auto;
}

/* Max site width without padding */
/* Max content width with side padding */
/* Max content width without */
/**
 * Use padding instead of margin so it is easier to select in pagebuilder
 */
.l-article {
  padding-inline: max(var(--inner-container-padding-inline), (100% - var(--article-container-max-width)) / 2);
  margin-block-end: var(--layout-default-block-margin);
}

/* .l-article--wider {
    @include container.article-wider();
    margin-block: var(--layout-default-block-margin);
} */
.l-container--inner {
  max-width: calc(var(--inner-container-max-width) + 2 * var(--inner-container-padding-inline));
  padding-inline: var(--inner-container-padding-inline);
}
.l-container--inner.-medium {
  max-width: var(--inner-container-medium-max-width);
}

.l-container--outer {
  max-width: var(--outer-container-max-width);
}

.content {
  display: flow-root;
}
.content.-align-center {
  margin-inline: auto;
}
.content.-small {
  max-width: 110rem;
}

@custom-media --footer-medium           (width < 810px);
@custom-media --footer-small            (width < 600px);
.l-footer {
  padding-block-start: 7rem;
  color: var(--footer-color, var(--color-primary-contrast-text));
  background-color: var(--footer-background-color, var(--color-primary));
}
.l-footer a {
  color: inherit;
}
.l-footer a:--focus {
  color: inherit;
  text-decoration: none;
}
.l-footer__logo {
  display: block;
  max-width: 20rem;
}
@media (--footer-medium) {
  .l-footer__logo {
    align-self: center;
  }
}
@media (--screen-mini) {
  .l-footer__logo {
    max-width: 14rem;
  }
}
@media (--footer-medium) {
  .l-footer {
    padding-block: 5rem 1rem;
  }
}
@media (--screen-mini-small) {
  .l-footer {
    padding-block: 3rem 0.7rem;
  }
}

.l-footer__top {
  display: flex;
  justify-content: space-around;
  max-width: 105rem;
  margin-block-end: 5rem;
  margin-inline: auto;
  font-weight: var(--l-footer-top-font-weight, inherit);
}
@media (--footer-medium) {
  .l-footer__top {
    flex-direction: column;
    align-items: center;
    margin-block-end: 4rem;
  }
  .l-footer__top > nav {
    align-self: center;
  }
}

.l-footer__bottom {
  display: flex;
  gap: 1rem 2rem;
  justify-content: center;
  margin-block-end: 3rem;
  font-size: 1.6rem;
}
@media (--footer-small) {
  .l-footer__bottom {
    flex-direction: column;
    align-items: center;
  }
}

/*
    Grids used in project
*/
.categories-grid, .products-grid {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(3, 1fr);
}
@media (--screen-medium-large) {
  .categories-grid, .products-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (--screen-medium-small) {
  .categories-grid, .products-grid {
    grid-template-columns: 1fr;
  }
}

.products-grid {
  grid-template-columns: repeat(auto-fill, minmax(32rem, 1fr));
}
.products-grid.-small {
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
}
.products-grid.-small .title--card {
  font-size: 1.7rem;
}

.product-grid {
  display: grid;
  grid-auto-rows: min-content;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  flex-basis: 50%;
  margin-block-end: 5rem;
}
.product-grid__link {
  position: relative;
  overflow: hidden;
  display: block;
}
.product-grid__link.-large {
  grid-column-end: -1;
  grid-column-start: 1;
}
.product-grid__link:--focus img {
  transform: scale(1.05);
  outline: none;
}
.product-grid__image {
  display: block;
  width: 100%;
  object-fit: cover;
  transition: transform 0.2s ease-in-out;
}
@media (--screen-medium) {
  .product-grid {
    margin-inline-end: 0;
  }
}

.contact-grid {
  display: flex;
  justify-content: space-between;
  margin-block-start: 12rem;
}
.contact-grid__phone {
  display: flex;
}
.contact-grid__phone > label {
  margin-inline-end: 0.5rem;
}
@media (--screen-medium) {
  .contact-grid {
    flex-direction: column-reverse;
    margin-block-start: 0;
  }
}

.shop-grid {
  display: flex;
  flex-direction: column;
  border-block-end: 1px solid var(--color-white);
}
.shop-grid > article {
  display: flex;
  gap: 2rem;
  align-items: center;
  padding-inline-end: 1em;
  border-inline-end: 1px solid var(--color-white);
}
.shop-grid > article:not(:first-child) {
  border-block-start: 2px solid var(--color-white);
}
.shop-grid figure {
  width: auto;
  background-color: var(--color-white);
}
.shop-grid .button {
  margin-block-start: 0;
}
.shop-grid__price {
  width: 2.5em;
  text-align: end;
}
.shop-grid__price-old {
  display: block;
  font-size: 80%;
  line-height: 1;
  color: var(--color-red);
  text-decoration: line-through;
  opacity: 0.7;
}
.shop-grid__title {
  flex: 1;
}

@custom-media --l-header-rounded (min-width: 1460px);
:root {
  --l-header-min-height: 21rem;
}

.l-header {
  color: var(--header-navigation-color, var(--color-primary));
}
.l-header a {
  color: inherit;
}
.l-header a:--focus {
  color: inherit;
}
.l-header__inner {
  max-width: calc(var(--inner-container-max-width) + 2 * var(--inner-container-padding-inline));
  padding-inline: var(--inner-container-padding-inline);
  display: flex;
  gap: 3rem;
  align-items: stretch;
  justify-content: space-between;
  padding-block: 1.7rem 2.3rem;
  box-shadow: var(--shadow-header);
}
@media (--show-desktop-menu) {
  .l-header__inner {
    z-index: 1;
    min-height: var(--l-header-min-height);
  }
}
@media (--show-mobile-menu) {
  .l-header__inner {
    position: static;
    padding-block: 1.2rem 1rem;
  }
}
.l-header__logo {
  flex: 1;
}
@media (--show-desktop-menu) {
  .l-header__logo {
    display: flex;
    align-items: center;
  }
}
@media (--show-mobile-menu) {
  .l-header__logo img {
    max-height: 12rem;
    object-fit: contain;
    object-position: left;
  }
}
.l-header__navigation {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
}
@media (--show-mobile-menu) {
  .l-header__navigation {
    display: none;
  }
  .-is-mobile-menu-open .l-header__navigation {
    position: absolute;
    z-index: var(--z-index-mobile-header);
    inset: 0;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-block-start: 14rem;
    color: var(--header-mobile-color, var(--color-primary-contrast-text));
    background: var(--header-mobile-background-color, var(--color-primary));
  }
  .-is-mobile-menu-open .l-header__navigation a:not([class]) {
    color: inherit;
  }
}
.l-header nav[aria-label=secondary] {
  display: flex;
  gap: 2rem;
  align-items: flex-start;
  justify-content: flex-end;
}
@media (--show-mobile-menu) {
  .l-header nav[aria-label=secondary] {
    flex-direction: column;
    align-items: center;
  }
}
.-is-mobile-menu-open .l-header {
  color: var(--header-mobile-color, var(--color-primary-contrast-text));
}

.product {
  display: flex;
  gap: 5vw;
  justify-content: space-between;
}
.product-images, .product-content {
  flex-basis: 50%;
}
@media (--screen-medium) {
  .product-content {
    margin-block-start: 4rem;
  }
}
.product__price {
  font-size: calc(1.9rem + (25 - 19) * (100vw - 32rem) / (1280 - 320));
  display: inline-block;
  font-weight: var(--font-primary-weight-bold);
  color: var(--color-primary);
}
@media (min-width: 80rem) {
  .product__price {
    font-size: 2.5rem;
  }
}
@media (max-width: 20rem) {
  .product__price {
    font-size: 1.9rem;
  }
}
.product__discount {
  color: var(--color-red);
  text-decoration: line-through;
  opacity: 0.7;
}
.product__vta {
  font-size: 55%;
  font-weight: var(--font-primary-weight-regular);
  color: var(--color-gray-dark);
}
.product__description {
  margin: 2em 0 3em;
}
.product--availability {
  display: inline-block;
  margin-block: 1rem 0.5rem;
  padding: 0.2em 0.5em 0.1em;
  font-family: var(--font-secondary);
  font-weight: var(--font-secondary-weight-medium);
}
.product--availability.-red {
  color: var(--color-red);
  border: 0.1rem solid var(--color-red);
}
.product--availability.-yellow {
  color: var(--color-yellow);
  border: 0.1rem solid var(--color-yellow);
}
.product--availability.-blue {
  color: var(--color-blue-light);
  border: 0.1rem solid var(--color-blue-light);
}
.product--availability.-green {
  color: var(--color-green);
  border: 0.1rem solid var(--color-green);
}
@media (--screen-medium) {
  .product {
    flex-direction: column;
  }
}

.contact-form {
  scroll-margin-top: 2em;
  max-width: 55rem;
}

.contact-success {
  font-family: var(--font-secondary);
  font-size: 3rem;
  line-height: 1.4;
  color: var(--color-primary);
}
@media (--screen-small) {
  .contact-success {
    font-size: 2.5rem;
  }
}

.content-image-wrapper {
  width: 100%;
  max-width: 111.5rem;
  margin: 0 auto;
}
.content-image-wrapper img {
  height: auto;
  max-height: 61.5rem;
  margin-block-end: 4rem;
  object-fit: cover;
}

.content-title {
  text-align: center;
}

.address {
  font-style: normal;
}
.address__item {
  cursor: pointer;
  display: inline-flex;
  margin-block-end: 3rem;
  text-decoration: none;
  transition: color 0.2s ease-in-out;
}
.address__item:--focus svg {
  fill: var(--address-svg-hover-color, currentcolor);
}
.address__item svg {
  position: relative;
  inset-block-start: 0.2rem;
  display: inline-block;
  margin-inline-end: 1.5rem;
  fill: var(--address-svg-color, var(--color-primary));
  transition: fill 0.2s ease-in-out;
}
@media (--screen-small) {
  .address__item svg {
    position: static;
  }
}
@media (--screen-medium) {
  .address {
    margin-block-start: 5rem;
  }
}

@custom-media --background-large    (width < 1600px);
.background-scroll > img {
  will-change: transform;
  position: absolute;
  z-index: -1;
  transform: translateY(var(--ty)) rotate(var(--r));
  opacity: 0.7;
  transition: transform 0.1s linear;
}
.background-scroll > img::before {
  content: attr(data-timing);
}
.background-scroll > img:first-of-type {
  inset-block-start: 30rem;
  inset-inline-start: 5rem;
  width: 4rem;
}
.background-scroll > img:nth-of-type(2) {
  inset-block-start: 43rem;
  inset-inline-start: 18rem;
  width: 4rem;
}
.background-scroll > img:nth-of-type(3) {
  inset-block-start: 68rem;
  inset-inline-start: 11rem;
  width: 4rem;
}
.background-scroll > img:nth-of-type(4) {
  inset-block-start: 62rem;
  inset-inline-end: 22rem;
  width: 4rem;
}
.background-scroll > img:nth-of-type(5) {
  inset-block-start: 40rem;
  inset-inline-end: 2rem;
  width: 4rem;
}
@media (--background-large) {
  .background-scroll > img {
    opacity: 0.17;
  }
}

.card {
  position: relative;
  z-index: 0;
  overflow: hidden;
  display: block;
  max-height: 37rem;
  text-decoration: none;
  background-color: white;
}
.card::after {
  content: "";
  position: absolute;
  inset-block-end: 0;
  inset-inline: 0;
  display: block;
  height: 80%;
  background: linear-gradient(180deg, transparent 30%, rgba(0, 0, 0, 0.55));
}
.card__content {
  position: absolute;
  z-index: 1;
  inset-block-end: 2rem;
  inset-inline: 2.2rem;
  line-height: 1.2;
  color: var(--card-color, var(--color-white));
  text-shadow: var(--card-text-shadow, none);
}
.card__content.-small {
  inset-block-end: 1rem;
  inset-inline: 1.6rem;
}
@media (--screen-medium) {
  .card__content {
    inset-block-end: 1.5rem;
    inset-inline-start: 1.7rem;
  }
}
@media (--screen-mini-small) {
  .card__content {
    inset-block-end: 1.3rem;
    inset-inline-start: 1.5rem;
  }
}
.card__image {
  width: 100%;
  height: auto;
  max-height: 35rem;
  opacity: 0.9;
  object-fit: cover;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
  mask-image: linear-gradient(to top, transparent 0%, black 20%);
}
.card__discount {
  text-decoration: line-through;
}
.card__price {
  display: inline-block;
  margin-block-start: 0.5rem;
  font-size: 1.8rem;
  font-weight: var(--font-primary-weight-medium);
  color: var(--card-color, var(--color-white));
}
.card:--focus img {
  transform: scale(1.05);
}

.checklist {
  margin-block-start: 1em;
  padding-inline-start: 0.7em;
}
.checklist > li {
  padding-inline-start: 0.5em;
}
.checklist > li::marker {
  content: var(--checklist-marker-content, "✓");
  color: var(--color-accent);
}

div.cc-revoke {
  display: none;
}

a.cc-btn {
  white-space: nowrap;
}

.discount-balloon {
  position: absolute;
  z-index: 1;
  inset-block-start: 4.7rem;
  inset-inline-end: 1.4rem;
  transform: translateY(-50%) rotate(-10deg);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 8rem;
  height: 8rem;
  font-size: 2.5rem;
  font-weight: var(--font-primary-weight-bold);
  line-height: 1;
  color: var(--color-white);
  background: var(--color-secondary);
  border-radius: 50%;
}

.divider {
  padding-inline: 0.6rem;
  color: #889395;
}
@media (--footer-medium) {
  .divider {
    display: none;
  }
}

.mce-content-body {
  display: flow-root;
  padding: 1em;
  background-color: inherit;
}

.footer-navigation {
  column-count: 2;
  column-gap: 12vw;
  margin-block: 1rem 0;
  padding: 0;
  font-size: 1.7rem;
  list-style-type: none;
}
.footer-navigation__item {
  display: block;
  margin-block-end: 1.6rem;
  font-size: 1.8rem;
}
.footer-navigation__link {
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
}
.footer-navigation__link:--focus {
  opacity: 0.8;
}
@media (--footer-medium) {
  .footer-navigation {
    column-count: 1;
    margin-block-start: 4rem;
    text-align: center;
  }
}
@media (--screen-mini) {
  .footer-navigation {
    font-size: 1.6rem;
  }
}

.datepicker {
  display: none;
}
.datepicker.active {
  display: block;
}

.datepicker-dropdown {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  padding-top: 4px;
}
.datepicker-dropdown.datepicker-orient-top {
  padding-top: 0;
  padding-bottom: 4px;
}

.datepicker-picker {
  display: inline-block;
  border-radius: 4px;
  background-color: hsl(0deg, 0%, 100%);
}
.datepicker-dropdown .datepicker-picker {
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
}
.datepicker-picker span {
  display: block;
  flex: 1;
  border: 0;
  border-radius: 4px;
  cursor: default;
  text-align: center;
  -webkit-touch-callout: none;
  user-select: none;
}

.datepicker-main {
  padding: 2px;
}

.datepicker-footer {
  box-shadow: inset 0 1px 1px rgba(10, 10, 10, 0.1);
  background-color: hsl(0deg, 0%, 96%);
}

.datepicker-grid, .datepicker-view .days-of-week, .datepicker-view, .datepicker-controls {
  display: flex;
}

.datepicker-grid {
  flex-wrap: wrap;
}

.datepicker-view .days .datepicker-cell, .datepicker-view .dow {
  flex-basis: 14.2857142857%;
}

.datepicker-view.datepicker-grid .datepicker-cell {
  flex-basis: 25%;
}

.datepicker-cell, .datepicker-view .week {
  height: 3.6rem;
  line-height: 3.6rem;
}

.datepicker-title {
  box-shadow: inset 0 -1px 1px rgba(10, 10, 10, 0.1);
  background-color: hsl(0deg, 0%, 96%);
  padding: 0.375rem 0.75rem;
  text-align: center;
  font-weight: 700;
}

.datepicker-header .datepicker-controls {
  padding: 2px 2px 0;
}
.datepicker-controls .button {
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  margin: 0;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  box-shadow: none;
  background-color: hsl(0deg, 0%, 100%);
  cursor: pointer;
  padding: calc(0.375em - 1px) 0.75em;
  height: 2.25em;
  vertical-align: top;
  text-align: center;
  line-height: 1.5;
  white-space: nowrap;
  color: hsl(0deg, 0%, 21%);
  font-size: 1.6rem;
}
.datepicker-controls .button:focus, .datepicker-controls .button:active {
  outline: none;
}
.datepicker-controls .button:hover {
  border-color: #b5b5b5;
  color: hsl(0deg, 0%, 21%);
}
.datepicker-controls .button:focus {
  border-color: hsl(51deg, 95%, 54%);
  color: hsl(0deg, 0%, 21%);
}
.datepicker-controls .button:focus:not(:active) {
  box-shadow: 0 0 0 0.125em rgba(249, 216, 26, 0.25);
}
.datepicker-controls .button:active {
  border-color: #4a4a4a;
  color: hsl(0deg, 0%, 21%);
}
.datepicker-controls .button[disabled] {
  cursor: not-allowed;
}
.datepicker-header .datepicker-controls .button {
  border-color: transparent;
  font-weight: bold;
}
.datepicker-header .datepicker-controls .button:hover {
  background-color: #f9f9f9;
}
.datepicker-header .datepicker-controls .button:focus:not(:active) {
  box-shadow: 0 0 0 0.125em rgba(255, 255, 255, 0.25);
}
.datepicker-header .datepicker-controls .button:active {
  background-color: #f2f2f2;
}
.datepicker-header .datepicker-controls .button[disabled] {
  box-shadow: none;
}
.datepicker-footer .datepicker-controls .button {
  margin: calc(0.375rem - 1px) 0.375rem;
  border-radius: 2px;
  width: 100%;
  font-size: 1.2rem;
}
.datepicker-controls .view-switch {
  flex: auto;
}
.datepicker-controls .prev-btn,
.datepicker-controls .next-btn {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
  width: 3.6rem;
}
.datepicker-controls .prev-btn.disabled,
.datepicker-controls .next-btn.disabled {
  visibility: hidden;
}

.datepicker-view .dow {
  height: 2.4rem;
  line-height: 2.4rem;
  font-size: 1.4rem;
  font-weight: 700;
}
.datepicker-view .week {
  width: 3.6rem;
  color: #b5b5b5;
  font-size: 1.2rem;
}
@media (max-width: 36rem) {
  .datepicker-view .week {
    width: 3.15rem;
  }
}

.datepicker-grid {
  width: 25.2rem;
}
@media (max-width: 36rem) {
  .calendar-weeks + .days .datepicker-grid {
    width: 22.05rem;
  }
}

.datepicker-cell:not(.disabled):hover {
  background-color: #f9f9f9;
  cursor: pointer;
}
.datepicker-cell.focused:not(.selected) {
  background-color: #e8e8e8;
}
.datepicker-cell.selected, .datepicker-cell.selected:hover {
  background-color: hsl(51deg, 95%, 54%);
  color: #fff;
  font-weight: 600;
}
.datepicker-cell.disabled {
  color: #dbdbdb;
}
.datepicker-cell.prev:not(.disabled), .datepicker-cell.next:not(.disabled) {
  color: hsl(0deg, 0%, 48%);
}
.datepicker-cell.prev.selected, .datepicker-cell.next.selected {
  color: #e6e6e6;
}
.datepicker-cell.highlighted:not(.selected):not(.range):not(.today) {
  border-radius: 0;
  background-color: hsl(0deg, 0%, 96%);
}
.datepicker-cell.highlighted:not(.selected):not(.range):not(.today):not(.disabled):hover {
  background-color: #eeeeee;
}
.datepicker-cell.highlighted:not(.selected):not(.range):not(.today).focused {
  background-color: #e8e8e8;
}
.datepicker-cell.today:not(.selected) {
  background-color: hsl(171deg, 100%, 41%);
}
.datepicker-cell.today:not(.selected):not(.disabled) {
  color: #fff;
}
.datepicker-cell.today.focused:not(.selected) {
  background-color: #00c4a7;
}
.datepicker-cell.range-end:not(.selected), .datepicker-cell.range-start:not(.selected) {
  background-color: #b5b5b5;
  color: #fff;
}
.datepicker-cell.range-end.focused:not(.selected), .datepicker-cell.range-start.focused:not(.selected) {
  background-color: #afafaf;
}
.datepicker-cell.range-start {
  border-radius: 4px 0 0 4px;
}
.datepicker-cell.range-end {
  border-radius: 0 4px 4px 0;
}
.datepicker-cell.range {
  border-radius: 0;
  background-color: #dbdbdb;
}
.datepicker-cell.range:not(.disabled):not(.focused):not(.today):hover {
  background-color: #d5d5d5;
}
.datepicker-cell.range.disabled {
  color: #c2c2c2;
}
.datepicker-cell.range.focused {
  background-color: #cfcfcf;
}
.datepicker-view.datepicker-grid .datepicker-cell {
  height: 7.2rem;
  line-height: 7.2rem;
}

.datepicker-input.in-edit {
  border-color: #f4d006;
}
.datepicker-input.in-edit:focus, .datepicker-input.in-edit:active {
  box-shadow: 0 0 0.25em 0.25em rgba(244, 208, 6, 0.2);
}

.datepicker-title {
  padding: 0.6rem 1.2rem;
}

.prev-btn,
.next-btn {
  padding-inline: 0.6rem;
}

.form__entry {
  position: relative;
  margin-block-end: var(--form-row-gap);
  padding: 0;
  border: 0;
}

.form__message {
  display: block;
  margin-block-start: 0;
  padding-block-start: 0.2em;
  font-size: 1.3rem;
}
.form__message.-error {
  color: var(--color-red);
}
.form__message:empty {
  display: none;
}

textarea:not([class]) + .form__message {
  margin-block-start: -0.7rem;
}

.form__row {
  display: grid;
  gap: 0 var(--form-column-gap);
  align-items: baseline;
}

.form__row {
  grid-template-columns: repeat(auto-fit, minmax(14em, 1fr));
}

.form__options--inline, .form__options {
  display: flex;
  flex-direction: column;
  gap: 0.3em 1.2em;
  margin-block-start: 0.6em;
}
.form__options--inline > *, .form__options > * {
  position: relative;
  display: flex;
  align-items: flex-start;
}

.form__options--inline {
  flex-direction: row;
  flex-wrap: wrap;
}

.form__button-input {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem var(--form-column-gap);
  align-items: center;
}
.form__button-input > button {
  margin: 0;
}
.form__button-input > input {
  flex: 1;
  min-width: 16rem;
  max-width: 20rem;
  text-align: center;
}
.form__button-input > input[disabled] {
  opacity: 1;
  border: 0;
}

:root {
  --form-row-gap: 2rem;
  --form-column-gap: 3rem;
}

:root {
  --hamburger-layer-color: currentcolor;
}

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 0 0;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}
.hamburger:hover {
  opacity: 1;
}
.hamburger.is-active:hover {
  opacity: 1;
}
.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: var(--hamburger-layer-color);
}

.hamburger-box {
  width: 19px;
  height: 17px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1.5px;
}
.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 19px;
  height: 3px;
  background-color: var(--hamburger-layer-color);
  border-radius: 3px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}
.hamburger-inner::before {
  top: -7px;
}
.hamburger-inner::after {
  bottom: -7px;
}

/*
 * Collapse
 */
.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--collapse .hamburger-inner::after {
  top: -14px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
}
.hamburger--collapse .hamburger-inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--collapse.is-active .hamburger-inner {
  transform: translate3d(0, -7px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--collapse.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
}
.hamburger--collapse.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger {
  display: block;
  outline: none;
  transition: transform 0.3s ease-in-out;
}

.homeSlider-wrapper {
  position: relative;
  margin-block-end: 4rem;
}
@media (--screen-medium) {
  .homeSlider-wrapper {
    margin-block-end: 2rem;
  }
}
.homeSlider__title {
  font-size: calc(3.5rem + (70 - 35) * (100vw - 32rem) / (1280 - 320));
  margin: 0 0 0.2em;
  font-family: var(--font-secondary);
  font-weight: var(--font-secondary-weight-bold);
  line-height: 1.1;
  color: var(--color-white);
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  text-transform: uppercase;
}
@media (min-width: 80rem) {
  .homeSlider__title {
    font-size: 7rem;
  }
}
@media (max-width: 20rem) {
  .homeSlider__title {
    font-size: 3.5rem;
  }
}
.homeSlider__content {
  font-size: calc(2.4rem + (45 - 24) * (100vw - 32rem) / (1280 - 320));
  position: absolute;
  z-index: 1;
  inset-block-start: 10rem;
  inset-inline: 10vw 5vw;
  font-family: var(--font-secondary);
  font-weight: var(--font-secondary-weight-bold);
  color: var(--color-white);
}
@media (min-width: 80rem) {
  .homeSlider__content {
    font-size: 4.5rem;
  }
}
@media (max-width: 20rem) {
  .homeSlider__content {
    font-size: 2.4rem;
  }
}
.homeSlider__content > p {
  margin-block-end: 1em;
  line-height: 1.3;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
}
@media (--screen-medium-large) {
  .homeSlider__content {
    inset-block-start: 4rem;
    inset-inline-start: 3vw;
  }
}
@media (--screen-medium-small) {
  .homeSlider__content {
    inset-block-start: 3rem;
  }
}
.homeSlider__image {
  display: block;
  width: 100%;
  height: auto;
  min-height: 40rem;
  object-fit: cover;
}
.homeSlider__item {
  position: relative;
}
.homeSlider__item.-first::before {
  content: "";
  position: absolute;
  z-index: 0;
  inset: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 47, 89, 0.5) 0, rgba(0, 47, 89, 0));
}
.homeSlider__controls {
  display: none;
}

.jumbotron {
  margin-block: 1rem 1vw;
}
.jumbotron > img {
  width: 100%;
  height: auto;
  min-height: 32rem;
  max-height: 60rem;
  object-fit: cover;
}
@media (--screen-huge) {
  .jumbotron {
    margin-block-end: 7rem;
  }
}
@media (--screen-medium) {
  .jumbotron {
    margin-block-end: 0;
  }
}

.language-toggle {
  position: relative;
  display: flex;
  margin: 0;
  padding-inline-start: 0;
  font-weight: var(--font-primary-weight-bold);
  list-style-type: none;
}
@media (--show-desktop-menu) {
  .language-toggle__item {
    display: none;
    order: 2;
  }
  .language-toggle__item.-active, .-open .language-toggle__item {
    display: inline-block;
  }
  .language-toggle__item.-active {
    order: 1;
  }
}
.language-toggle__link {
  display: block;
  text-decoration: none;
  text-transform: uppercase;
  transition: color 0.2s ease-in-out;
}
@media (--show-desktop-menu) {
  .-active .language-toggle__link::after {
    content: "";
    position: absolute;
    inset-block-start: 0.9rem;
    inset-inline-end: 0;
    display: block;
    width: 1.5rem;
    height: 1.2rem;
    background-color: currentcolor;
    mask-image: url("../images/angle-down.svg");
    mask-position: right;
    mask-size: 1.3rem;
  }
  .-open .-active .language-toggle__link::after {
    mask-image: url("../images/angle-up.svg");
  }
}
.language-toggle__link:--focus {
  color: var(--color-secondary);
}
@media (--header-medium) {
  .-overlay .language-toggle__link {
    color: var(--color-white);
  }
}
@media (--show-desktop-menu) {
  .language-toggle {
    flex-direction: column;
    width: 4.4rem;
  }
}
@media (--show-mobile-menu) {
  .language-toggle {
    gap: 1em;
  }
}
@media (--header-medium) {
  .language-toggle {
    display: none;
  }
  .-overlay .language-toggle {
    inset-block-start: 3.2rem;
    inset-inline-start: 0.5rem;
    display: flex;
    background-color: transparent;
  }
}

@custom-media --navigation-large     (width < 1460px);
@custom-media --navigation-medium     (width < 1150px);
.main-navigation {
  column-gap: calc(1.5rem + (25 - 15) * (100vw - 128rem) / (1440 - 1280));
  display: flex;
  flex-wrap: wrap;
  row-gap: 2.5rem;
  align-items: center;
  justify-content: center;
  margin-block-end: 5rem;
  padding-inline-start: 0;
  list-style-type: none;
}
@media (min-width: 90rem) {
  .main-navigation {
    column-gap: 2.5rem;
  }
}
@media (max-width: 80rem) {
  .main-navigation {
    column-gap: 1.5rem;
  }
}
.main-navigation__item {
  display: inline-block;
  color: var(--main-navigation-color, var(--color-primary-contrast-text));
  box-shadow: var(--main-navigation-box-shadow, none);
}
.main-navigation__item:nth-of-type(odd) {
  transform: rotate(var(--main-navigation-rotate-odd, 3deg));
}
.main-navigation__item:nth-of-type(even) {
  transform: rotate(var(--main-navigation-rotate-even, -3deg));
}
@media (--header-medium) {
  .main-navigation__item {
    margin-block-end: 3rem;
  }
}
.main-navigation__item:nth-of-type(1) > a {
  background-color: var(--main-navigation-color-1, var(--color-primary));
}
.main-navigation__item:nth-of-type(1) > a:--focus {
  background-color: var(--main-navigation-color-1-hover, var(--color-primary-light));
}
.main-navigation__item:nth-of-type(2) > a {
  background-color: var(--main-navigation-color-2, var(--color-primary));
}
.main-navigation__item:nth-of-type(2) > a:--focus {
  background-color: var(--main-navigation-color-2-hover, var(--color-primary-light));
}
.main-navigation__item:nth-of-type(3) > a {
  background-color: var(--main-navigation-color-3, var(--color-primary));
}
.main-navigation__item:nth-of-type(3) > a:--focus {
  background-color: var(--main-navigation-color-3-hover, var(--color-primary-light));
}
.main-navigation__item:nth-of-type(4) > a {
  background-color: var(--main-navigation-color-4, var(--color-primary));
}
.main-navigation__item:nth-of-type(4) > a:--focus {
  background-color: var(--main-navigation-color-4-hover, var(--color-primary-light));
}
.main-navigation__item:nth-of-type(5) > a {
  background-color: var(--main-navigation-color-5, var(--color-primary));
}
.main-navigation__item:nth-of-type(5) > a:--focus {
  background-color: var(--main-navigation-color-5-hover, var(--color-primary-light));
}
.main-navigation__item:nth-of-type(6) > a {
  background-color: var(--main-navigation-color-6, var(--color-primary));
}
.main-navigation__item:nth-of-type(6) > a:--focus {
  background-color: var(--main-navigation-color-6-hover, var(--color-primary-light));
}
.main-navigation__link {
  font-size: calc(1.8rem + (21 - 18) * (100vw - 120rem) / (1400 - 1200));
  display: inline-block;
  padding: var(--main-navigation-padding, 0.6em 1em 0.5em);
  font-family: var(--main-navigation-font-family, var(--font-secondary));
  font-weight: var(--main-navigation-font-weight, var(--font-secondary-weight-medium));
  color: var(--color-white);
  text-decoration: none;
  text-transform: var(--main-navigation-text-transform, uppercase);
  letter-spacing: 0.7px;
  border: var(--main-navigation-border, 0);
  border-radius: var(--main-navigation-border-radius, 0);
  transition: background-color 0.2s ease-in-out;
}
@media (min-width: 87.5rem) {
  .main-navigation__link {
    font-size: 2.1rem;
  }
}
@media (max-width: 75rem) {
  .main-navigation__link {
    font-size: 1.8rem;
  }
}
.-overlay .main-navigation__link {
  padding-inline: 1.1em;
}
@media (--screen-mini) {
  .-overlay .main-navigation__link {
    padding-inline: 0.7em;
  }
}
@media (--navigation-medium) {
  .main-navigation__link {
    padding-inline: 0.7em;
  }
}
@media (--show-mobile-menu) {
  .main-navigation {
    flex-direction: column;
  }
}
@media (--header-medium) {
  .main-navigation {
    display: none;
  }
  .-overlay .main-navigation {
    display: flex;
    flex-direction: column;
    margin-block-end: 2rem;
  }
}

.bounce > li {
  animation-duration: 0.9s;
}
.bounce > li:nth-of-type(2) {
  animation-delay: 0.02s;
}
.bounce > li:nth-of-type(3) {
  animation-delay: 0.06s;
}
.bounce > li:nth-of-type(4) {
  animation-delay: 0.1s;
}
.bounce > li:nth-of-type(5) {
  animation-delay: 0.14s;
}
.bounce > li:nth-of-type(6) {
  animation-delay: 0.18s;
}
.bounce > li:nth-of-type(even) {
  animation-name: bounce-even;
}
.bounce > li:nth-of-type(odd) {
  animation-name: bounce-odd;
}

.menu-button {
  display: none;
}
@media (--show-mobile-menu) {
  .menu-button {
    z-index: var(--z-index-menu-button);
    display: flex;
    align-items: center;
  }
  .menu-button .hamburger {
    margin-inline-start: 1rem;
  }
}

.newsletter {
  max-width: 45rem;
  margin: 0 auto;
}
.newsletter.-subscribed {
  max-width: none;
  margin: 10rem auto;
  text-align: center;
}
.newsletter-wrapper {
  padding-block: 2rem 4rem;
  background-color: var(--color-gray-light);
}
.newsletter__title {
  margin-block-end: 1rem;
  font-family: var(--font-secondary);
  font-size: 4.5rem;
  line-height: 1.25;
  color: var(--color-primary);
}
@media (--screen-mini-small) {
  .newsletter__title {
    font-size: 3.3rem;
  }
}
.newsletter__description {
  font-size: 1.8rem;
  font-weight: var(--font-primary-weight-bold);
}

.newsletter-form {
  margin-block-start: 4rem;
}
.newsletter-form input {
  margin-block-end: 1.5rem !important;
  border-color: transparent !important;
}
.newsletter-form input:focus, .newsletter-form input:active {
  border-color: var(--color-primary) !important;
}
@media (--screen-mini-small) {
  .newsletter-form {
    margin-block-start: 3rem;
  }
}

details.shop-accordion > summary {
  cursor: pointer;
  display: flex;
  gap: 2em;
  align-items: center;
  justify-content: space-between;
  padding: 0.3em 1em;
  color: var(--color-white);
  background-color: var(--color-brown-light);
  border: 1px solid;
}
details.shop-accordion > summary::-webkit-details-marker {
  display: none;
}
details.shop-accordion > summary::marker {
  display: none;
  color: transparent; /* solution for hiding in firefox */
}
details.shop-accordion > summary::after {
  content: "+";
  display: inline-block;
  margin-inline-start: 0.8rem;
  font-size: 3rem;
  font-weight: bold;
  color: inherit;
  transition: rotate 0.3s ease;
}
details.shop-accordion > summary h4 {
  display: inline-block;
  margin-block: 0;
  margin-block-start: 0;
  color: inherit;
  text-shadow: none;
}
details.shop-accordion > summary:hover {
  background-color: var(--color-brown);
}
details.shop-accordion[open] > summary::after {
  content: "-";
  margin-inline-end: 0.4rem;
}

.slider-wrapper {
  position: relative;
  display: none;
  margin-block-end: 3rem;
}
@media (--screen-medium) {
  .slider-wrapper {
    display: block;
    border-block-end: 1px solid var(--color-gray-dark);
  }
}
@media (--screen-mini-small) {
  .slider-wrapper {
    margin-block-end: 1rem;
  }
}
.slider__image {
  width: 100%;
  height: auto;
}
.slider__controls {
  font-size: 3rem;
}
.slider__controls_left, .slider__controls_right {
  cursor: pointer;
  position: absolute;
  inset-block-start: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 5rem;
  height: 5rem;
  color: var(--color-white);
  background-color: var(--color-primary);
  border: none;
  transition: 0.3s ease-in-out background-color;
}
.slider__controls_left::after, .slider__controls_right::after {
  content: "";
  position: absolute;
  inset: 0;
  display: block;
  background-color: var(--color-primary-contrast-text);
  mask-position: center;
}
.slider__controls_left > img, .slider__controls_right > img {
  display: none;
}
.slider__controls_left:--focus, .slider__controls_right:--focus {
  background-color: var(--color-primary);
  outline: none;
}
@media (--screen-small) {
  .slider__controls_left, .slider__controls_right {
    width: 4rem;
    height: 4rem;
  }
}
.slider__controls_left {
  inset-inline-start: 0;
}
.slider__controls_left::after {
  mask-image: url("../images/angle-left.svg");
}
.slider__controls_right {
  inset-inline-end: 0;
}
.slider__controls_right::after {
  mask-image: url("../images/angle-right.svg");
}

.tns-outer > button {
  display: none;
}

.tns-outer > button {
  display: none;
}

.social-bar {
  display: flex;
  gap: 2rem;
}
.social-bar svg {
  fill: currentcolor;
  transition: fill 0.2s ease-in-out;
}

.textblock {
  padding: 1em;
  border: 3px solid var(--color-primary-light);
}

.toc {
  margin-block-start: 5rem;
  font-size: 1.9rem;
  border-block-end: 1px solid var(--color-gray-dark);
}
.toc > ul {
  display: block;
}
.toc > ul > li {
  display: inline-block;
  margin-inline-end: 1.5em;
}
.toc > ul > li:last-child {
  margin-inline-end: 0;
}
@media (--screen-mini) {
  .toc > ul > li {
    margin-inline-end: 4vw;
  }
}
.toc a:not([class]) {
  font-family: var(--font-secondary);
  font-weight: bold;
  color: var(--color-secondary);
  text-decoration: none;
}
.toc a:not([class]):--focus {
  text-decoration: underline;
}

.top-message {
  margin: 0;
  font-weight: bold;
  text-align: center;
}
.top-message.-demo {
  color: white;
  background-color: red;
}

.top-navigation {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem 3rem;
  align-items: center;
  justify-content: center;
  font-weight: var(--font-primary-weight-bold);
}
.top-navigation a {
  display: inline-block;
  text-decoration: none;
}
.top-navigation a:--focus {
  text-decoration: underline;
}
@media (--show-mobile-menu) {
  .top-navigation {
    flex-direction: column;
  }
}

.youtube-iframe {
  margin: 5rem 0;
}
@media (--screen-medium) {
  .youtube-iframe {
    margin-block: 3rem 0;
  }
}

.h-padding-vertical {
  padding-block: 3rem 7rem;
}
@media (--screen-mini-small) {
  .h-padding-vertical {
    padding-block-end: 5rem;
  }
}

.h-margin-bottom-0 {
  margin-block-end: 0;
}

.h-margin-bottom-1 {
  margin-block-end: 1em;
}

.h-gray {
  font-size: 1.5rem;
  color: var(--color-gray-dark);
}
.h-gray.-large {
  font-size: inherit;
}

.h-lowercase {
  text-transform: lowercase;
}

.h-centered {
  display: block;
  text-align: center;
}

.h-hidden {
  display: none;
}

/* stylelint-disable declaration-colon-space-after, max-empty-lines */
:root {
  --color-blue-white: hsl(200, 31%, 94%);
  --color-blue: #2F6EB7;
  --color-blue-hover: #2F6EB7;
  --color-blue-light: #4898DE;
  --color-blue-light-hover: #4898DE;
  --color-green: #98C455;
  --color-green-hover: #98C455;
  --color-yellow: #F5C445;
  --color-yellow-hover: #F5C445;
  --color-red: #EA5048;
  --color-red-hover: #EA5048;
  --color-pink: #AD348A;
  --color-pink-hover: #AD348A;
  --color-primary: var(--color-blue);
  --color-primary-contrast-text: var(--color-white);
  --color-secondary: var(--color-blue-light);
  --color-secondary-contrast-text: var(--color-black);
  --color-accent: var(--color-green);
  --font-primary: "Open Sans", sans-serif;
  --font-secondary: "Square Peg", sans-serif;
  --font-primary-weight-regular: 400;
  --font-primary-weight-medium: 500;
  --font-primary-weight-bold: 700;
  --font-secondary-weight-medium: 500;
  --font-secondary-weight-bold: 800;
  --title-margin-block: .75em .5em;
  --title-line-height: 1;
  --main-navigation-color-1: var(--color-blue);
  --main-navigation-color-1-hover: var(--color-blue-light);
  --main-navigation-color-2: var(--color-green);
  --main-navigation-color-2-hover: var(--color-green-hover);
  --main-navigation-color-3: var(--color-yellow);
  --main-navigation-color-3-hover: var(--color-yellow-hover);
  --main-navigation-color-4: var(--color-red);
  --main-navigation-color-4-hover: var(--color-red-hover);
  --main-navigation-color-5: var(--color-pink);
  --main-navigation-color-5-hover: var(--color-pink-hover);
  --main-navigation-color-6: var(--color-blue);
  --main-navigation-color-6-hover: var(--color-blue-light);
  --main-navigation-font-family: var(--font-primary);
  --main-navigation-text-transform: none;
  --main-navigation-font-weight: bold;
  --cookieconsent-button-background-color: var(--color-green);
}

@font-face {
  font-family: "Square Peg";
  font-weight: 400;
  font-style: normal;
  src: url("../../fonts/square-peg/square-peg-v2-latin-regular.woff2") format("woff2");
}
.homeSlider__title {
  font-size: calc(5.5rem + (90 - 55) * (100vw - 32rem) / (1280 - 320));
  text-transform: none;
}
@media (min-width: 80rem) {
  .homeSlider__title {
    font-size: 9rem;
  }
}
@media (max-width: 20rem) {
  .homeSlider__title {
    font-size: 5.5rem;
  }
}

.homeSlider__content {
  font-size: calc(4.4rem + (65 - 44) * (100vw - 32rem) / (1280 - 320));
}
@media (min-width: 80rem) {
  .homeSlider__content {
    font-size: 6.5rem;
  }
}
@media (max-width: 20rem) {
  .homeSlider__content {
    font-size: 4.4rem;
  }
}

.title--footer {
  font-family: var(--font-primary);
}

.main-navigation__link.-home {
  display: none;
}

.title--1, h1:not([class]) {
  font-size: calc(5.8rem + (80 - 58) * (100vw - 32rem) / (1280 - 320));
}
@media (min-width: 80rem) {
  .title--1, h1:not([class]) {
    font-size: 8rem;
  }
}
@media (max-width: 20rem) {
  .title--1, h1:not([class]) {
    font-size: 5.8rem;
  }
}

.title--2, h2:not([class]) {
  font-size: calc(4.5rem + (63 - 45) * (100vw - 32rem) / (1280 - 320));
}
@media (min-width: 80rem) {
  .title--2, h2:not([class]) {
    font-size: 6.3rem;
  }
}
@media (max-width: 20rem) {
  .title--2, h2:not([class]) {
    font-size: 4.5rem;
  }
}

.title--3, h3:not([class]) {
  font-size: calc(3.2rem + (48 - 32) * (100vw - 32rem) / (1280 - 320));
}
@media (min-width: 80rem) {
  .title--3, h3:not([class]) {
    font-size: 4.8rem;
  }
}
@media (max-width: 20rem) {
  .title--3, h3:not([class]) {
    font-size: 3.2rem;
  }
}

.title--4, h4:not([class]) {
  font-size: calc(2.8rem + (42 - 28) * (100vw - 32rem) / (1280 - 320));
}
@media (min-width: 80rem) {
  .title--4, h4:not([class]) {
    font-size: 4.2rem;
  }
}
@media (max-width: 20rem) {
  .title--4, h4:not([class]) {
    font-size: 2.8rem;
  }
}

.product__price {
  color: var(--color-green);
}