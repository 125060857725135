/* stylelint-disable declaration-colon-space-after, max-empty-lines */
:root {
    --color-blue-white:                 hsl(200, 31%, 94%);
    --color-blue:                       #2F6EB7;
    --color-blue-hover:                 #2F6EB7;
    --color-blue-light:                 #4898DE;
    --color-blue-light-hover:           #4898DE;
    --color-green:                      #98C455;
    --color-green-hover:                #98C455;
    --color-yellow:                     #F5C445;
    --color-yellow-hover:               #F5C445;
    --color-red:                        #EA5048;
    --color-red-hover:                  #EA5048;
    --color-pink:                       #AD348A;
    --color-pink-hover:                 #AD348A;

    --color-primary:                    var(--color-blue);
    --color-primary-contrast-text:      var(--color-white);

    --color-secondary:                  var(--color-blue-light);
    --color-secondary-contrast-text:    var(--color-black);

    --color-accent:                     var(--color-green);


    --font-primary:                     'Open Sans', sans-serif;
    --font-secondary:                   'Square Peg', sans-serif;

    --font-primary-weight-regular:      400;
    --font-primary-weight-medium:       500;
    --font-primary-weight-bold:         700;

    --font-secondary-weight-medium:     500;
    --font-secondary-weight-bold:       800;

    --title-margin-block:               .75em .5em;
    --title-line-height:                1;

    --main-navigation-color-1: var(--color-blue);
    --main-navigation-color-1-hover: var(--color-blue-light);

    --main-navigation-color-2: var(--color-green);
    --main-navigation-color-2-hover: var(--color-green-hover);

    --main-navigation-color-3: var(--color-yellow);
    --main-navigation-color-3-hover: var(--color-yellow-hover);

    --main-navigation-color-4: var(--color-red);
    --main-navigation-color-4-hover: var(--color-red-hover);

    --main-navigation-color-5: var(--color-pink);
    --main-navigation-color-5-hover: var(--color-pink-hover);

    --main-navigation-color-6: var(--color-blue);
    --main-navigation-color-6-hover: var(--color-blue-light);

    --main-navigation-font-family: var(--font-primary);
    --main-navigation-text-transform: none;
    --main-navigation-font-weight: bold;

    --cookieconsent-button-background-color: var(--color-green);
}
