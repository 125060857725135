@use 'config.scss';
@use 'fonts.scss';
@use '../../mixins/responsive';

.homeSlider__title {
    @include responsive.size(font-size, 55, 90);
    text-transform: none;
}

.homeSlider__content {
    @include responsive.size(font-size, 44, 65);
}

.title--footer {
    font-family: var(--font-primary);
}

.main-navigation__link.-home {
    display: none;
}


.title--1, h1:not([class]) {
    @include responsive.size(font-size, 58, 80);
}

.title--2, h2:not([class]) {
    @include responsive.size(font-size, 45, 63);
}

.title--3, h3:not([class]) {
    @include responsive.size(font-size, 32, 48);
}

.title--4, h4:not([class]) {
    @include responsive.size(font-size, 28, 42);
}

.product__price {
    color: var(--color-green);
}
