@use '../mixins/responsive';

@import '~tiny-slider/dist/tiny-slider.css';

.homeSlider {
    &-wrapper {
        position: relative;
        margin-block-end: 4rem;

        @media (--screen-medium) {
            margin-block-end: 2rem;
        }
    }

    &__title {
        @include responsive.size(font-size, 35, 70);

        margin: 0 0 .2em;

        font-family: var(--font-secondary);
        font-weight: var(--font-secondary-weight-bold);
        line-height: 1.1;
        color: var(--color-white);
        text-shadow: 0 0 5px rgba(0, 0, 0, .4);
        text-transform: uppercase;
    }

    &__content {
        @include responsive.size(font-size, 24, 45);

        position: absolute;
        z-index: 1;
        inset-block-start: 10rem;
        inset-inline: 10vw 5vw;

        font-family: var(--font-secondary);
        font-weight: var(--font-secondary-weight-bold);
        color: var(--color-white);

        > p {
            margin-block-end: 1em;
            line-height: 1.3;
            text-shadow: 0 0 5px rgba(0, 0, 0, .4);
        }

        @media (--screen-medium-large) {
            inset-block-start: 4rem;
            inset-inline-start: 3vw;
        }

        @media (--screen-medium-small) {
            inset-block-start: 3rem;
        }
    }

    &__image {
        display: block;

        width: 100%;
        height: auto;
        min-height: 40rem;

        object-fit: cover;
    }

    &__item {
        position: relative;

        &.-first::before { //temporary
            content: '';

            position: absolute;
            z-index: 0;
            inset: 0;

            width: 100%;
            height: 100%;

            background: linear-gradient(to bottom, rgba(0, 47, 89, .5) 0, rgba(0, 47, 89, 0));
        }
    }

    &__controls {
        display: none;
        //@extend .slider__controls;
    }
}
