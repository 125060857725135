/*
    Grids used in project
*/

.categories-grid {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(3, 1fr);

    @media (--screen-medium-large) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (--screen-medium-small) {
        grid-template-columns: 1fr;
    }
}

.products-grid {
    @extend .categories-grid;

    grid-template-columns: repeat(auto-fill, minmax(32rem, 1fr));

    &.-small {
        grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));

        .title--card {
            font-size: 1.7rem;
        }
    }
}

.product-grid {
    display: grid;
    grid-auto-rows: min-content;
    grid-gap: 2rem;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    flex-basis: 50%;

    margin-block-end: 5rem;

    &__link {
        position: relative;
        overflow: hidden;
        display: block;

        &.-large {
            grid-column-end: -1;
            grid-column-start: 1;
        }

        &:--focus img {
            transform: scale(1.05);
            outline: none;
        }
    }

    &__image {
        display: block;
        width: 100%;
        object-fit: cover;
        transition: transform .2s ease-in-out;
    }

    @media (--screen-medium) {
        margin-inline-end: 0;
    }
}

.contact-grid {
    display: flex;
    justify-content: space-between;
    margin-block-start: 12rem;

    &__phone {
        display: flex;

        > label {
            margin-inline-end: .5rem;
        }
    }

    @media (--screen-medium) {
        flex-direction: column-reverse;
        margin-block-start: 0;
    }
}

.shop-grid {
    display: flex;
    flex-direction: column;
    border-block-end: 1px solid var(--color-white);

    > article {
        display: flex;
        gap: 2rem;
        align-items: center;

        padding-inline-end: 1em;

        border-inline-end: 1px solid var(--color-white);

        &:not(:first-child) {
            border-block-start: 2px solid var(--color-white);
        }
    }

    figure {
        width: auto;
        background-color: var(--color-white);
    }

    .button {
        margin-block-start: 0;
    }

    &__price {
        width: 2.5em;
        text-align: end;

        &-old {
            display: block;

            font-size: 80%;
            line-height: 1;
            color: var(--color-red);
            text-decoration: line-through;

            opacity: .7;
        }
    }

    &__title {
        flex: 1;
    }
}
