@use '../mixins/responsive';

%button {
    cursor: pointer;

    display: inline-block;

    margin: .7em 1em .7em 0;
    padding: .85em 1.4em .9em;

    font-family: var(--button-font-family, var(--font-primary));
    font-size: 1.7rem;
    font-weight: var(--font-primary-weight-bold);
    line-height: 1.25;
    color: var(--color-primary-contrast-text);
    text-decoration: none;

    background-color: var(--color-primary);
    border: 0;
    border-radius: var(--button-border-radius, 4px);

    transition: background-color .2s ease-out;

    &::after {
        content: '';

        display: inline-block;

        width: 1rem;
        height: 1rem;
        margin-inline-start: .8em;

        background-color: currentcolor;

        transition: transform .2s ease-in-out;

        mask-image: url('../images/angle-right.svg');
        mask-position: right center;
        mask-size: contain;
    }

    &:--focus {
        background-color: var(--color-primary-light);

        &::after {
            transform: translateX(.4rem);
        }
    }
}

:where(button:not([class])), .button:where(:not(.prev-btn):not(.next-btn):not(.view-switch)) {
    @extend %button;
}

.button {
    &.-large {
        @include responsive.size(font-size, 18, 23);
    }

    &.-no-arrow {
        &::after {
            display: none;
        }
    }
}

.button--slider {
    @extend .button;

    @include responsive.size(font-size, 18, 23);
}
